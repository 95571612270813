import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { InformationIcon } from 'atoms/Icons';
import DateTimeRangePicker from 'atoms/components/DateTimeRangePicker';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { SubscriptionPlan } from 'models/Subscriptions';
import { getApiHrefByRel } from 'Api/ApiCommonFunctions';
import ConfirmationModal from 'components/_shared/Modal/Confirmation';
import Api from 'Api';
import { parseTemplate } from 'url-template';
import { dispatch } from 'store';
import { SubscriptionsApi } from 'store/_Api/Subscriptions';
import { prettifyDate } from '_utils/date';
interface FormData {
  Name: string;
  Price: number;
  VatRate: number;
  MaxConcurrentUses: number;
  PeriodDays: number;
  SalesPeriodFrom: string;
  SalesPeriodTo: string;
  Description: string;
  Accessibility: string;
}
type Props = {
  handleClose: () => void;
  setCarParksLink: (link: string) => void;
  setwhiteListedUsersLink: (link: string) => void;

  subscriptionPlan: SubscriptionPlan;
};
const GeneralTab: FC<Props> = ({
  subscriptionPlan,
  setCarParksLink,
  setwhiteListedUsersLink,
  handleClose,
}): JSX.Element => {
  const [subscription, setSubscription] = useState(subscriptionPlan);

  const [subscriptionToMakePrivate, setSubscriptionToMakePrivate] = useState<
    SubscriptionPlan | undefined
  >();
  const [subscriptionToMakePublic, setSubscriptionToMakePublic] = useState<
    SubscriptionPlan | undefined
  >();
  const [subscriptionToPublish, setSubscriptionToPublish] = useState<
    SubscriptionPlan | undefined
  >();
  const [subscriptionToCancel, setSubscriptionToCancel] = useState<
    SubscriptionPlan | undefined
  >();
  const [subscriptionToRemove, setSubscriptionToRemove] = useState<
    SubscriptionPlan | undefined
  >();
  const theme = useTheme();
  const { t } = useTranslation();
  const [salesPeriod, setSalesPeriod] = useState<Date | Date[] | string>([]);

  const initialValues: FormData = {
    Name: subscription.Name,
    Price: subscription.Price,
    VatRate: subscription.VatRate,
    PeriodDays: subscription.PeriodDays,
    SalesPeriodFrom: subscription.SalesPeriodFrom,
    SalesPeriodTo: subscription.SalesPeriodTo,
    MaxConcurrentUses: subscription.MaxConcurrentUses,
    Description: subscription.Description,
    Accessibility: subscription.Accessibility,
  };

  const getSelfSubscription = async () => {
    try {
      const selfLink = getApiHrefByRel(subscription.Links, 'self') || '';
      const response = await Api.get(selfLink);
      const data = response.data as SubscriptionPlan;
      const carParksLink = getApiHrefByRel(data.Links, 'car-parks') || '';
      if (carParksLink) {
        const carParksLinkParsed = parseTemplate(
          decodeURI(carParksLink),
        ).expand({
          pagesize: 50,
          page: 0,
        });
        setCarParksLink(carParksLinkParsed);
      }
      const whilelistedusersLink =
        getApiHrefByRel(data.Links, 'whitelisted-users') || '';
      if (whilelistedusersLink) {
        const whilelistedusersLinkParsed = parseTemplate(
          decodeURI(whilelistedusersLink),
        ).expand({
          pagesize: 50,
          page: 0,
        });
        console.log(whilelistedusersLink);

        setwhiteListedUsersLink(whilelistedusersLinkParsed);
      }
      setSubscription(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSelfSubscription();
  }, []);

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required('Name is required'),
    Price: Yup.number()
      .min(0, 'Price must be a non-negative number')
      .required('Price is required'),
    PeriodDays: Yup.number()
      .min(1, 'Period Days must be at least 1')
      .required('Period Days is required'),
  });

  const success = {
    '&:hover': {
      background: theme.colors.success.lighter,
    },
    background: theme.colors.success.lighter,
    color: theme.palette.success.main,
  };

  const error = {
    '&:hover': {
      background: theme.colors.error.lighter,
    },
    background: theme.colors.error.lighter,
    color: theme.palette.error.main,
  };

  const hasLink = (linkRel: string) => {
    return getApiHrefByRel(subscription.Links, linkRel);
  };
  const onConfirmMakePrivate = async (subscription: SubscriptionPlan) => {
    try {
      const makePrivateLink =
        getApiHrefByRel(subscription.Links, 'make-private') || '';
      const response = await Api.post(makePrivateLink);
      if (response.status === 200) {
        setSubscriptionToMakePrivate(undefined);
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onConfirmMakePublic = async (subscription: SubscriptionPlan) => {
    try {
      const makePublicLink =
        getApiHrefByRel(subscription.Links, 'make-public') || '';
      const response = await Api.post(makePublicLink);
      if (response.status === 200) {
        setSubscriptionToMakePublic(undefined);
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onConfirmPublish = async (subscription: SubscriptionPlan) => {
    try {
      const publishLink = getApiHrefByRel(subscription.Links, 'publish') || '';
      const response = await Api.post(publishLink);
      if (response.status === 200) {
        setSubscriptionToPublish(undefined);
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onConfirmCancel = async (subscription: SubscriptionPlan) => {
    try {
      const cancelLink = getApiHrefByRel(subscription.Links, 'cancel') || '';
      const response = await Api.post(cancelLink);
      if (response.status === 200) {
        setSubscriptionToCancel(undefined);
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onConfirmRemove = async (subscription: SubscriptionPlan) => {
    try {
      const removeLink = getApiHrefByRel(subscription.Links, 'remove') || '';
      const response = await Api.delete(removeLink);
      if (response.status < 400) {
        dispatch(SubscriptionsApi.util.invalidateTags(['subscriptions']));
        setSubscriptionToRemove(undefined);
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box p={3}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values): Promise<void> => {
          const updateSubscriptionLink =
            getApiHrefByRel(subscription.Links, 'update') || '';
          if (updateSubscriptionLink) {
            try {
              const response = await Api.post(updateSubscriptionLink, {
                Name: values.Name,
                Description: values.Description,
              });
              if (response.status === 200) {
                dispatch(
                  SubscriptionsApi.util.invalidateTags(['subscriptions']),
                );
                handleClose();
              }
            } catch (error) {
              console.error(error);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleSubmit,
          handleChange,
          isSubmitting,
          touched,
          values,
        }): JSX.Element => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                alignContent="center"
                flexDirection="column"
                spacing={2}
              >
                <Grid width="60%" item xs={8}>
                  <Typography fontWeight="bolder" my={1} color="secondary">
                    {t('Name')}:
                  </Typography>
                  <TextField
                    error={Boolean(touched.Name && errors.Name)}
                    margin="normal"
                    helperText={touched.Name && errors.Name}
                    fullWidth
                    name="Name"
                    placeholder="Write subscription name here..."
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.Name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid display="flex" alignItems="center" my={1}>
                    <InformationIcon opacity={1} />
                    <Typography
                      fontWeight="bold"
                      ml={0.5}
                      my={1}
                      color="secondary"
                    >
                      {t('Description')} :
                    </Typography>
                  </Grid>
                  <TextField
                    error={Boolean(touched.Description && errors.Description)}
                    margin="normal"
                    helperText={touched.Description && errors.Description}
                    fullWidth
                    name="Description"
                    placeholder="Write subscription description here..."
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="text"
                    value={values.Description}
                    variant="outlined"
                    multiline
                    rows={5}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    gap: 2,
                    pl: 2,
                  }}
                >
                  <TextField
                    error={Boolean(touched.PeriodDays && errors.PeriodDays)}
                    margin="normal"
                    helperText={touched.PeriodDays && errors.PeriodDays}
                    label={t('Period Days')}
                    name="PeriodDays"
                    disabled={Boolean(subscription)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.PeriodDays}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.Price && errors.Price)}
                    margin="normal"
                    helperText={touched.Price && errors.Price}
                    label={t('Price')}
                    name="Price"
                    onBlur={handleBlur}
                    disabled={Boolean(subscription)}
                    onChange={handleChange}
                    type="number"
                    value={values.Price}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid display="flex" alignItems="center" my={1}>
                    <InformationIcon opacity={1} />
                    <Typography
                      marginLeft={0.4}
                      fontWeight="bold"
                      color="secondary"
                    >
                      {t('Vat Rate')} (%):
                    </Typography>
                  </Grid>
                  <TextField
                    error={Boolean(touched.VatRate && errors.VatRate)}
                    margin="normal"
                    helperText={touched.VatRate && errors.VatRate}
                    name="VatRate"
                    onBlur={handleBlur}
                    disabled={Boolean(subscription)}
                    onChange={handleChange}
                    type="number"
                    value={values.VatRate}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  {Boolean(subscription) ? (
                    <TextField
                      margin="normal"
                      fullWidth
                      label={t('Sales Period')}
                      name="salesPeriod"
                      onBlur={handleBlur}
                      disabled={Boolean(subscription)}
                      onChange={handleChange}
                      type="text"
                      value={
                        prettifyDate(values.SalesPeriodFrom) +
                        ' - ' +
                        prettifyDate(values.SalesPeriodTo)
                      }
                      variant="outlined"
                    />
                  ) : (
                    <DateTimeRangePicker
                      defaultDateRange={salesPeriod}
                      handleChange={setSalesPeriod}
                      placeholder={t('Sales Period')}
                      disabled={Boolean(true)}
                      style={{ marginRight: '16px' }}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.MaxConcurrentUses && errors.MaxConcurrentUses,
                    )}
                    margin="normal"
                    helperText={
                      touched.MaxConcurrentUses && errors.MaxConcurrentUses
                    }
                    label={t('Max Concurrent Uses')}
                    name="MaxConcurrentUses"
                    disabled={Boolean(subscription)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="number"
                    value={values.MaxConcurrentUses}
                    variant="outlined"
                  />
                  <TextField
                    margin="normal"
                    sx={{
                      ml: 2,
                    }}
                    label={t('Accessibility')}
                    name="Accessibility"
                    disabled={Boolean(subscription)}
                    value={values.Accessibility}
                    variant="outlined"
                  />
                </Grid>
              </Grid>

              <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {hasLink('publish') && (
                  <Button
                    sx={{
                      mt: 3,
                      ...success,
                    }}
                    color="primary"
                    size="large"
                    onClick={() => setSubscriptionToPublish(subscription)}
                    variant="contained"
                  >
                    {t('Publish')}
                  </Button>
                )}
                {hasLink('cancel') && (
                  <Button
                    sx={{
                      mt: 3,
                      ...error,
                    }}
                    color="primary"
                    size="large"
                    onClick={() => setSubscriptionToCancel(subscription)}
                    variant="contained"
                  >
                    {t('Cancel Subscription')}
                  </Button>
                )}
                {hasLink('remove') && (
                  <Button
                    sx={{
                      mt: 3,
                      ...error,
                    }}
                    color="primary"
                    size="large"
                    onClick={() => setSubscriptionToRemove(subscription)}
                    variant="contained"
                  >
                    {t('Remove Subscription')}
                  </Button>
                )}
                {hasLink('make-private') && (
                  <Button
                    sx={{
                      mt: 3,
                    }}
                    color="primary"
                    size="large"
                    onClick={() => setSubscriptionToMakePrivate(subscription)}
                    variant="contained"
                  >
                    {t('Make Private')}
                  </Button>
                )}
                {hasLink('make-public') && (
                  <Button
                    sx={{
                      mt: 3,
                    }}
                    color="primary"
                    size="large"
                    onClick={() => setSubscriptionToMakePublic(subscription)}
                    variant="contained"
                  >
                    {t('Make Public')}
                  </Button>
                )}
                <Button
                  sx={{
                    mt: 3,
                  }}
                  color="primary"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={isSubmitting}
                  type="submit"
                  size="large"
                  variant="contained"
                >
                  {t('Save Subscription')}
                </Button>
              </Grid>
            </form>
          );
        }}
      </Formik>

      {subscriptionToMakePrivate && (
        <ConfirmationModal
          title="confirm"
          children={
            <>
              <Typography
                variant="subtitle1"
                sx={{
                  p: 4,
                  textAlign: 'center',
                  fontSize: `${theme.typography.pxToRem(15)}`,
                }}
                gutterBottom
                component="h3"
              >
                {t('Are you sure you want to make')}
                <strong> {subscriptionToMakePrivate.Name} </strong>{' '}
                {t('private')}
              </Typography>
              <Divider />
            </>
          }
          onClose={() => {
            setSubscriptionToMakePrivate(undefined);
          }}
          onConfirm={() => onConfirmMakePrivate(subscriptionToMakePrivate)}
        />
      )}
      {subscriptionToRemove && (
        <ConfirmationModal
          title="confirm"
          children={
            <>
              <Typography
                variant="subtitle1"
                sx={{
                  p: 4,
                  textAlign: 'center',
                  fontSize: `${theme.typography.pxToRem(15)}`,
                }}
                gutterBottom
                component="h3"
              >
                {t('Are you sure you want to remove')}
                <strong> {subscriptionToRemove.Name} </strong>{' '}
                {t('subscription')}
              </Typography>
              <Divider />
            </>
          }
          onClose={() => {
            setSubscriptionToRemove(undefined);
          }}
          onConfirm={() => onConfirmRemove(subscriptionToRemove)}
        />
      )}
      {subscriptionToMakePublic && (
        <ConfirmationModal
          title="confirm"
          children={
            <>
              <Typography
                variant="subtitle1"
                sx={{
                  p: 4,
                  textAlign: 'center',
                  fontSize: `${theme.typography.pxToRem(15)}`,
                }}
                gutterBottom
                component="h3"
              >
                {t('Are you sure you want to make')}
                <strong> {subscriptionToMakePublic.Name} </strong> {t('public')}
              </Typography>
              <Divider />
            </>
          }
          onClose={() => setSubscriptionToMakePublic(undefined)}
          onConfirm={() => onConfirmMakePublic(subscriptionToMakePublic)}
        />
      )}
      {subscriptionToPublish && (
        <ConfirmationModal
          title="confirm"
          children={
            <>
              <Typography
                variant="subtitle1"
                sx={{
                  p: 4,
                  textAlign: 'center',
                  fontSize: `${theme.typography.pxToRem(15)}`,
                }}
                gutterBottom
                component="h3"
              >
                {t('Are you sure you want to')}
                <strong> {subscriptionToPublish.Name} </strong> {t('publish')}
              </Typography>
              <Divider />
            </>
          }
          onClose={() => {
            setSubscriptionToPublish(undefined);
          }}
          onConfirm={() => {
            onConfirmPublish(subscriptionToPublish);
          }}
        />
      )}
      {subscriptionToCancel && (
        <ConfirmationModal
          title="confirm"
          children={
            <>
              <Typography
                variant="subtitle1"
                sx={{
                  p: 4,
                  textAlign: 'center',
                  fontSize: `${theme.typography.pxToRem(15)}`,
                }}
                gutterBottom
                component="h3"
              >
                {t('Are you sure you want to cancel subscription for')}
                <strong> {subscriptionToCancel.Name} </strong>
              </Typography>
              <Divider />
            </>
          }
          onClose={() => setSubscriptionToCancel(undefined)}
          onConfirm={() => onConfirmCancel(subscriptionToCancel)}
        />
      )}
    </Box>
  );
};

export default GeneralTab;
